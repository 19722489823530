import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../model/apiresponse.model';
import { environment } from 'src/environments/environment';
import { Asset } from '../../model/asset.model';
import { ApiPaginatedResponse } from '../../model/api-paginated-response.model';
import { AssetType } from '../../model/assetType.model';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(private http: HttpClient) {}

  get(organizationId: string, assetId: string): Observable<ApiResponse<Asset | null>> {
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/asset/${assetId}`,
      {
        params: {
          'with[]': [ 'organizationalUnitOwner', 'employeeOwner' ],
        }
      }
    );
  }

  getAssetTypeList(): Observable<ApiResponse<AssetType[] | undefined>> {
    return this.http.get<any>(
      `${environment.apiUrl}/asset/type`
    );
  }

  list(organizationId: string): Observable<ApiResponse<Asset[] | null>> {
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/asset`,
      {
        params: {
          'with[]': [ 'organizationalUnitOwner', 'employeeOwner' ],
          orderBy: 'name',
          asc: 1
        }
      }
    );
  }

  paginatedList(organizationId: string, page: number, pageSize: number): Observable<ApiPaginatedResponse<Asset[] | null>> {
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/asset`,
      {
        params: {
          'with[]': [ 'organizationalUnitOwner', 'employeeOwner', 'businessProcesses' ],
          'withCount[]': [ 'businessProcesses' ],
          page: page,
          limit: pageSize,
          orderBy: 'name',
          asc: 1
        }
      }
    );
  }

  filteredPaginatedList( organizationId: string, page: number, pageSize: number, filter: string, filterValue: string): Observable<ApiPaginatedResponse<Asset[] | null>> {
    let params: { [Key: string]: any } = {
      page: page,
      limit: pageSize,
      orderBy: 'name',
      asc: 1,
      'with[]': ['organizationalUnitOwner', 'employeeOwner', 'businessProcesses'],
      'withCount[]': ['businessProcesses']
    };

    if (filterValue != null && filterValue !== '') {
      params['orderBy'] = filter;
      params['filterBy'] = filter;
      params['filter'] = filterValue;
    }

    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/asset`,
      {
        params: params
      }
    );
  }

  create(
    organizationId: string,
    asset: Asset
  ): Observable<ApiResponse<Asset | null>> {

    return this.http.post<any>(
      `${environment.apiUrl}/${organizationId}/asset`,
      asset       
    )
  }

  delete(organizationId: string, assetId: string): Observable<ApiResponse<Asset | null>> {
    return this.http.delete<any>(
      `${environment.apiUrl}/${organizationId}/asset/${assetId}`
    );
  }

  edit(
    organizationId: string,
    asset: Asset
  ): Observable<ApiResponse<Asset | null>> {
    
    return this.http.put<any>(
      `${environment.apiUrl}/${organizationId}/asset/${asset.id}`,
      asset       
    )
  }

}
